<div class="col-md-12">

    <div class="alert alert-warning text-center" role="alert" *ngIf="!bank_id">
        Selecione o banco que você irar depositar!
      </div>
 

    <div class="row d-flex justify-content-center">

        <div class="col-lg-6" *ngFor="let item of banks">

            <div class="card mb-3 mt-3" (click)="selectBank(item.id)" [ngClass]="{'border border-primary':bank_id==item.id,'opacity':bank_id && bank_id!=item.id}">

                <div class="card-header text-center" style="background:#f4f5fa !important">

                    <h5 class="mt-1">{{item.name}}</h5>

                </div>

                <div class="d-flex justify-content-center">

                    <img class="mb-3 img-bank" src="{{item.ico}}">

                </div>
                <div class="table-responsive">
                <table class="table table-striped">

                    <tbody>

                        <tr>

                            <td class="text-left text">Agência:</td>

                            <td class="text-right text-normal">{{item.agency}}</td>

                        </tr>

                        <tr>

                            <td class="text-left text">Conta:</td>

                            <td class="text-right text-normal">{{item.current_account}}</td>

                        </tr>

                        <tr>

                            <td class="text-left text">Favorecido:</td>

                            <td class="text-right text-normal">{{item.favored}}</td>

                        </tr>

                        <tr>

                            <td class="text-left text">CPF/CNPJ:</td>

                            <td class="text-right text-normal">{{item.cnpj_cpf | mask:"CPF_CNPJ"}}</td>

                        </tr>

                    </tbody>

                </table>
</div>

            </div>

        </div>
    </div>

    <div class="alert alert-primary text-center" role="alert" *ngIf="bank_id">
        Este depósito será processado no banco <span class="text-bold">{{bankName}}</span> caso o banco seja incorreto o depósito poderá ser estornado.
    </div>


    <div *ngIf="imgURL == '' && bank_id " class="form-group text-center" >
        <label for="file" type="button" class="btn btn-secondary optionButton">
            <img src="../../../../../assets/media/icons/paymentIcons/anexo.svg"> Anexar Comprovante
        </label>
        <input type="file" id="file" (change)="onChange($event)">
    </div>

    <div *ngIf="imgURL != ''" class="proofOptions text-center">
        <img [src]="imgURL">

        <div class="row">
            <label type="button" class="btn btn-outline-danger optionButtonImg" (click)="imgURL= ''">
                Cancelar
            </label>
            <label type="button" class="btn btn-outline-secondary optionButtonImg" (click)="uploadProof()">
                Enviar
            </label>
        </div>

    </div>
</div>