import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ChargeService } from './../_services/charge.service';

@Component({
  selector: 'app-billet-option',
  templateUrl: './billet-option.component.html',
  styleUrls: ['./billet-option.component.scss']
})
export class BilletOptionComponent implements OnInit {

  @Input() id : any;

  constructor(
    private router: Router,
    private charge: ChargeService,
  ) { }

  ngOnInit(): void {
  }

  billetScreen(){ 
      this.charge.createBillet(this.id).pipe().subscribe(res => { 
        if (res.success){
          window.open(`http://cobrancas.nativopagamentos.com/billet/${this.id}`,  "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=400,left=500,width=1000,height=400");
        }
      }); 
  }

  downloadBillet(){
    window.open();
  }

}
