import { ScreenErrorComponent } from './screen-error/screen-error.component';
import { BilletComponent } from './billet/billet.component';
import { ChargeScreenComponent } from './charge-screen/charge-screen.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: ChargeScreenComponent
  },
  {
    path: 'error',
    component: ScreenErrorComponent
  },
  {
    path: ':token',
    component: ChargeScreenComponent
  },
  {
    path: 'billet/:token',
    component: BilletComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
