<div class="noprint">
    <h2 class="Recibo-do-sacado">Instruções de Impressão</h2>
    <ul class="boleto_body">
        <li>Imprima em impressora jato de tinta (ink jet) ou laser em qualidade normal ou alta (Não use modo econômico).
        </li>
        <li>Utilize folha A4 (210 x 297 mm) ou Carta (216 x 279 mm) e margens mínimas à esquerda e à direita do
            formulário.</li>
        <li>Corte na linha indicada. Não rasure, risque, fure ou dobre a região onde se encontra o código de barras.
        </li>
        <li>Caso não apareça o código de barras no final, pressione F5 para atualizar esta tela.</li>
        <li>Caso tenha problemas ao imprimir, copie a sequencia numérica abaixo e pague no caixa eletrônico ou no
            internet banking:</li>
    </ul>
    <span class="infos">Linha Digitável: {{date_bilet?.charge?.billet_digitable_line}}</span>
    <span class="infos">Valor: R$ {{ date_bilet?.charge?.value | currency:" "}}</span>

    <div class="linha-pontilhada" style="margin-bottom: 20px;">Recibo do sacado</div>
    <!-- <div class="info-empresa">
        <div class="img-header">
            <img alt="logotipo" width="250px"
                src="assets/media/logos/se1.png">
        </div>
        <div style="display: inline-block; vertical-align: super; margin-left:10px">
            <div><strong>NATIVO PAGAMENTOS EIRELI</strong></div>
            <div>33.964.509/0001-03</div>
            <div>Av Multirão 2653, Sala B 3301, Edifício Orion Business</div>
            <div>74093-250 - Goiania - GO</div>
        </div>

        <br>
        <div class="linha-pontilhada" style="margin-bottom: 20px;">&nbsp;</div>
    </div> -->

</div>
<div class="folha">

    <!-- Cabeçalho -->
    <table class="boleto_titulo">
        <tbody>
            <tr>
                <th>
                    <img src="{{photoCompany? photoCompany : 'assets/media/logos/se1.png'}}" height="40px">
                </th>
                <th>
                    <div class="Recibo-do-sacado">Recibo do sacado</div>
                </th>
            </tr>
        </tbody>
    </table>
    <div class="header clearfix boleto">
        <table cellpadding="2" class="boleto_body">
            <tbody>
                <tr>
                    <td>
                        <small>Sacado</small>
                        <br> {{date_bilet?.client?.name}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <small>CPF/CNPJ</small>
                        <br> {{date_bilet?.client?.doc_number | mask:'000.000.000-00 ||00.000.000/0000-00"'}}
                    </td>
                    <td>
                        <small>Vencimento</small>
                        <br> {{date_bilet?.charge?.due_date| date:"dd/MM/yyyy"}}
                    </td>
                    <td>
                        <small>Nosso Número</small>
                        <br> {{date_bilet?.charge?.billet_reference}}
                    </td>
                    <td>
                        <small>Valor do documento</small>
                        <br>
                        <div>R$ {{ date_bilet?.charge?.value | currency:" "}}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="header clearfix">
        <div class="autenticacao_mecanica"><small>Autenticação mecânica</small></div>
    </div>
    <div class="boleto [[tipoVisualizacao]]">
        <div id="corte"></div>
        <table class="boleto_titulo">
            <tbody>
                <tr>
                    <th class="banco_logo">
                        <img align="left"
                            src="{{photoCompany? photoCompany : 'assets/media/logos/se1.png'}}"
                            height="40px">
                    </th>
                    <th class="banco">218-6</th>
                    <th class="linha_dig">{{date_bilet?.charge?.billet_digitable_line}} </th>
                </tr>
            </tbody>
        </table>
        <br>
        <div class="header clearfix">
            <table cellpadding="2" class="boleto_body">
                <tbody>
                    <tr>
                        <td colspan="6">
                            <small>Local de Pagamento</small>
                            <br> Pagável em qualquer banco, mesmo após vencimento.
                        </td>
                        <td width="30%">
                            <small>Vencimento</small>
                            <br>
                            <div class="valor"> {{date_bilet?.charge?.due_date| date:"dd/MM/yyyy"}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <small>Cedente/Beneficário</small>
                            <br> Nativo Pagamentos Pagamentos E Cobrancas
                        </td>
                        <td colspan="2">
                            <small>CPF/CNPJ</small>
                            <br> 33.964.509/0001-03
                        </td>
                        <td>
                            <small>Agência/Conta</small>
                            <br>
                            <div class="valor">0001/4020685</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <small>Data do Documento</small>
                            <br> {{date_bilet?.charge?.created_at | date:'dd/MM/yyyy'}}
                        </td>
                        <td colspan="2">
                            <small>N° do Documento</small>
                            <br> {{date_bilet?.charge?.reference}}
                        </td>
                        <td>
                            <small>Espécie</small>
                            <br> Outro
                        </td>
                        <td>
                            <small>Aceite</small>
                            <br> Não
                        </td>
                        <td>
                            <small>Data de Processamento</small>
                            <br> {{date_bilet?.charge?.created_at | date:'dd/MM/yyyy'}}
                        </td>
                        <td>
                            <small>Nosso Número</small>
                            <br>
                            <div class="valor">{{date_bilet?.charge?.billet_reference}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <small>Uso do Banco</small>
                            <br>
                        </td>
                        <td colspan="2">
                            <small>Carteira</small>
                            <br> Cbr Simples
                        </td>
                        <td>
                            <small>Moeda</small>
                            <br> R$
                        </td>
                        <td>
                            <small>Quantidade</small>
                            <br>
                        </td>
                        <td>
                            <small>Valor Moeda</small>
                            <br>
                        </td>
                        <td>
                            <small> (=) Valor do Documento</small>
                            <br>
                            <div class="valor">R$ {{date_bilet?.charge?.value | currency:" "}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" rowspan="5">
                            <small>Instruções de recebimento</small>
                            <br>
                            <strong> Não receber com cheque</strong>
                            <br>
                            <strong> Não receber após {{date_bilet?.charge?.due_date | date:'dd/MM/yyyy'}}</strong>
                            <br>
                            <strong> </strong>
                            <br>
                            <strong> </strong>
                            <br>
                            <br>
                            <br>
                            <strong></strong>
                            <br>
                            <br>
                            <strong>Cobrança intermediada por Nativo Pagamentos </strong>
                        </td>
                        <td>
                            <small> ( - ) Desconto / Abatimento</small>
                            <br>
                            <div *ngIf="date_bilet?.charge?.discount_type == 'percentage'" class="valor">{{ date_bilet?.charge?.discount_value * date_bilet?.charge?.value | currency:"BRL"}}</div>
                            <div *ngIf="date_bilet?.charge?.discount_type == 'value'" class="valor">{{ date_bilet?.charge?.discount_value | currency:"BRL"}}</div>
                            <div *ngIf="!date_bilet?.charge?.discount_type">&nbsp;</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <small> ( - ) Outras Deduções</small>
                            <br>
                            <div class="valor">&nbsp;</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <small> ( + ) Mora/Multa</small>
                            <br>
                            <div class="valor">
                                <div *ngIf="date_bilet?.charge?.fee_type == 'percentage'" class="valor">{{ date_bilet?.charge?.discount_value * date_bilet?.charge?.value | currency:"BRL"}}</div>
                                <div *ngIf="date_bilet?.charge?.fee_type == 'value'" class="valor">{{ date_bilet?.charge?.discount_value | currency:"BRL"}}</div>
                                <div *ngIf="!date_bilet?.charge?.fee_type">&nbsp;</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <small> ( + ) Outros Acréscimos</small>
                            <br>
                            <div class="valor">&nbsp;</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <small> ( = ) Valor Cobrado</small>
                            <br>
                            <div class="valor">{{date_bilet?.charge?.billet_value | currency:"BRL"}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7">
                            <small>Sacado</small>
                            <br> {{date_bilet?.client?.name}}
                            <br> {{date_bilet?.client?.address?.street}} Nº {{date_bilet?.client?.address?.number}}
                            {{date_bilet?.client?.address?.complement}} - {{date_bilet?.address?.district}}
                            <br> {{date_bilet?.client?.address?.cep}} -
                            {{date_bilet?.client?.address?.city}}/{{date_bilet?.client?.address?.state}}
                            <br>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <table class="footer-aut">
                <tbody>
                    <tr>
                        <td style="width: 1%;">
                            <img style="margin-left: -12px;display: table;" src="{{date_bilet?.charge?.billet_barcode}}">

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>