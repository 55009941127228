
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod'; 

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(
        private router: Router) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 400 || err.status === 500) {
            localStorage.clear();
            this.router.navigateByUrl('https://www.nativopagamentos.com/');
            return of(err.message);
        }

        return throwError(err);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 

        return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
    }
}