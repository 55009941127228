<div class="row title">
    <a target="_blank" href="https://www.nativopagamentos.com/">
        <img class="ml-4 mt-2 logo-img" src="../../../../../assets/media/logos/se1.png" width="170px">
    </a>
    <h5 class="ml-5  title-data">
        Serviço de cobranças Nativo Pagamentos
    </h5>
</div>

<div class="center">
    <div class="row">
        <img class="logo-img" src="../../../../../assets/media/icons/warning.svg">
        <h4>
            Oops!
        </h4>
    </div>
    <h5 class="col-sm-12">
        Parece que o token de cobrança informado é inválido!
    </h5>
</div>