import { ChargeService } from './../_services/charge.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-billet',
  templateUrl: './billet.component.html',
  styleUrls: ['./billet.component.scss']
})
export class BilletComponent implements OnInit {

  public token: any;
  public date_bilet: any;
  public photoCompany: any;

  constructor(private route: ActivatedRoute,
    private charge: ChargeService,
    private router: Router) { }

  ngOnInit(): void {
    this.getParams();
  }

  public getParams() {
    this.route.params.subscribe((params: any) => {
      this.token = params['token'];
      if (this.token) { 
        this.charge.getChargeExternalSub(this.token).pipe(take(1)).subscribe(res =>{  
          this.date_bilet = res.data;
          this.photoCompany = res.data.user.photo_company;
        });
      }
      else {
        this.router.navigate(['/home']);
      }
    });
  }

}
