<div class="card">
    <div class="card-body text-center">
        <img class="mat-elevation-z8 billet-img"
            src="../../../../../assets/media/icons/paymentIcons/boletoImage.png">
        <div class="row">
            <label class="btn btn-secondary optionButton" (click)="billetScreen()">
                <img src="../../../../../assets/media/icons/paymentIcons/printer.svg">
                Imprimir
            </label> 
        </div>

    </div>
</div>