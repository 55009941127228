import { ChargeService } from './../_services/charge.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-transf-option',
  templateUrl: './transf-option.component.html',
  styleUrls: ['./transf-option.component.scss']
})
export class TransfOptionComponent implements OnInit {
  public proofToUpload?: File;
  public isCollapsed = true;
  public form: FormGroup;
  public files?: Set<File>;
  public imgURL: any = "";
  public imagePath: any;
  public bank_id: any;
  public bankName: any;

  @Input() banks: any[] = [];
  @Input() id: any;

  constructor(
    private charge: ChargeService,
    private fb: FormBuilder,private route: ActivatedRoute,
    private router: Router
  ) {
    this.form = fb.group({
      id: [this.id],
      receipt: [null],
      bank_id: [this.bank_id]
    });
  }

  ngOnInit(): void {
  }

  // updateData() { 
  //   this.route.params.subscribe((params: any) => {
  //     let token = params['token'];  
  //       this.charge.publicTransaction(token).pipe(take(1)).subscribe(res =>{            
  //       this.charge.updateChargeData(res.data);
  //       });      
  //   });

  // }

  selectBank(id: any) {
    if (this.bank_id == id) {
      this.bank_id = null;
    } else {
      this.banks.forEach(bank => {
        if (bank.id == id) {
          this.bankName = bank.name;
          this.bank_id = id;
        }
      });
    }
  }

  onChange(event: any) {
    const selectedFiles = <FileList>event.srcElement.files;
    const fileNames = [];
    this.files = new Set();
    for (let i = 0; i < selectedFiles.length; i++) {
      fileNames.push(selectedFiles[i].name);
      this.files.add(selectedFiles[i]);
    }
    if (this.files && this.files.size > 0) {
      var reader = new FileReader();
      this.imagePath = selectedFiles;
      reader.readAsDataURL(selectedFiles[0]);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
      }
    }
  }

  uploadProof() {
    if (this.files) {
      this.charge.uploadProof(this.files, this.id,this.bank_id).subscribe(res => { 
        if (res.success) {
          Swal.fire({
            icon: 'success',
            title: 'Comprovante enviado com sucesso!',
            showConfirmButton: false,
            timer: 1500
          });
          window.location.reload();
        }
        else {
          Swal.fire({
            icon: 'error',
            title: res.message,
            showConfirmButton: false,
            timer: 1500
          });
          // this.status = false;        
          // this.message = 'Não foi possível anexar no momento!';
        }
      },
        err => {
          Swal.fire({
            icon: 'error',
            title: 'Não foi possível anexar no momento!',
            showConfirmButton: false,
            timer: 1500
          });
          // this.status = false;        
          // this.message = 'Não foi possível anexar no momento!';

        });
    }
  }
}
