import { ChargeService } from './../_services/charge.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-card-option',
  templateUrl: './card-option.component.html',
  styleUrls: ['./card-option.component.scss']
})
export class CardOptionComponent implements OnInit {
  public imgBank: any;
  public form: FormGroup;
  public back: Boolean = true;
  public parcelsData: any[] = [];


  @Input() id: any;
  @Input() value: any;
  // @Input() parcels: any[] = [];

  constructor(
    private fb: FormBuilder,
    private charge: ChargeService,
  ) {
    this.form = fb.group({ 
      id: [this.id],
      type: ['CreditCard', Validators.required], 
      installments: [1, Validators.required],
      card_number: ['0000 0000 0000 0002', Validators.required],
      holder: ['Teste Holder', Validators.required],
      expiration_date: ['12/2030', Validators.required],
      security_code: ['123', Validators.required],
      brand: ['Visa', Validators.required],
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      id: this.id, 
    });
    this.calcParcels();
  }

  calcParcels() {
    this.charge.getParcels(this.id).pipe(take(1)).subscribe(res => { 
      if (!res.success) {   
        Swal.fire({
          icon: 'error',
          title: 'Falha ao pegar parcelas',
          showConfirmButton: false,
          timer: 1500
        });
      }else{
        this.parcelsData = res.data;
      }
    })
  }
 

  paymentCard() {
    this.charge.cardPayment(this.form.value).pipe(take(1)).subscribe(res => {
      if (res.success) {
        Swal.fire({
          icon: 'success',
          title: res.message,
          showConfirmButton: false,
          timer: 1500
        });
        this.charge.publicTransaction(this.id).pipe(take(1)).subscribe(res => {
          if (res.success) {
            this.charge.updateChargeData(res.data)
          }
        });
      }
      else {
        Swal.fire({
          icon: 'error',
          title: res.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    })
  }

  getCardFlag(cardnumber: string) {
    var cardnumber = cardnumber.replace(/[^0-9]+/g, '');
    this.imgBank = '';
    var cards: any = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/
    };

    for (var flag in cards) {
      if (cards[flag].test(cardnumber)) {
        console.log(flag)
        if (flag == 'visa') {
          this.imgBank = '../../../../../assets/media/bandeiras/visa.png';
        }
        if (flag == 'mastercard') {
          this.imgBank = '../../../../../assets/media/bandeiras/master.png';
        }
        if (flag == 'elo') {
          this.imgBank = '../../../../../assets/media/bandeiras/elo.png';
        }
        if (flag == 'hipercard') {
          this.imgBank = '../../../../../assets/media/bandeiras/hiper.png';
        }
        if (flag == 'amex') {
          this.imgBank = '../../../../../assets/media/bandeiras/american.png'
        }
        this.form.patchValue({ brand: flag });
        return flag;
      }
    }

    return false;
  }
 

}
