import { ChargeModel } from './../_models/charge.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
const API = environment.API;

@Injectable({
  providedIn: 'root'
})
export class ChargeService {
  private currentChargeDataSubject: BehaviorSubject<ChargeModel>;
  public currentChargeData: Observable<ChargeModel>;

  constructor(
    private http: HttpClient
  ) {
    this.currentChargeDataSubject = new BehaviorSubject<ChargeModel>(null as any);
    this.currentChargeData = this.currentChargeDataSubject.asObservable();
  }

  public updateChargeData(value: ChargeModel){
    this.currentChargeDataSubject.next(value);
  }

  publicTransaction(token: any){
    return this.http.get<any>(`${API}/charge/external/${token}`)
  }
  
  getChargeExternalSub(id: any){
    return this.http.get<any>(`${API}/charge/sub/external/${id}`)
  }
  createBillet(id: any){
    return this.http.post<any>(`${API}/charge/external/createBillet`, {id: id})
  }

  cardPayment(form: any){
    return this.http.post<any>(`${API}/charge/external/createChargeCard`, form)
  }

  getParcels(id: any){
    return this.http.get<any>(`${API}/charge/sub/external/${id}/parcels`)
  }

  uploadProof(files: Set<File>, id: any,bank_id:any){
    const formData = new FormData();
    formData.append('id', id);
    formData.append('bank_id', bank_id);
    files.forEach(receipt => formData.append('receipt', receipt, receipt.name));
    return this.http.post<any>(`${API}/charge/external/uploadReceipt/`, formData);
  }
}
