import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardOptionComponent } from './card-option/card-option.component';
import { BilletOptionComponent } from './billet-option/billet-option.component';
import { TransfOptionComponent } from './transf-option/transf-option.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChargeScreenComponent } from './charge-screen/charge-screen.component';
import { BilletComponent } from './billet/billet.component';
import { ScreenErrorComponent } from './screen-error/screen-error.component';
import { Interceptor } from './_interceptor/interceptor';


@NgModule({
  declarations: [
    AppComponent,
    CardOptionComponent,
    BilletOptionComponent,
    TransfOptionComponent,
    ChargeScreenComponent,
    BilletComponent,
    ScreenErrorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgbModule,

  ],
  providers: [
    {
      provide:HTTP_INTERCEPTORS, useClass:Interceptor, multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
