<div class="body">
    <div *ngIf="form.value.type == null" class="row">
        <label (click)="form.patchValue({type: 'CreditCard'})" type="button"
            class="btn btn-outline-secondary optionButtonCredit">
            Crédito
        </label>
        <label (click)="form.patchValue({type: 'DebitCard'})" type="button"
            class="btn btn-outline-secondary optionButtonDebit">
            Débito
        </label>
    </div>

    <div *ngIf="form.value.type != null" class="row">
        <div class="col-md-6">
            <form [formGroup]="form">
                <label class="text">Número do cartão:</label>
                <input (click)="back = true" (focus)="back = true" (change)="getCardFlag(form.value.card_number)" type="text"
                    class="form-control" formControlName="card_number" mask="0000 0000 0000 0000">
                <label class="text">Nome do titular:</label>
                <input (click)="back = true" (focus)="back = true" type="text" class="form-control" formControlName="holder" oninput="this.value = this.value.toUpperCase()">
                <div class="row">
                    <div class="col-sm-6">
                        <label class="text">Validade</label>
                        <input (click)="back = true" (focus)="back = true" type="text" class="form-control" formControlName="expiration_date" mask="00/0000" [dropSpecialCharacters]="false">
                    </div>
                    <div class="col-sm-6">
                        <label class="text">CVV</label>
                        <input (click)="back = false" (focus)="back = false"type="text" class="form-control" formControlName="security_code" mask="000">
                    </div>

                </div>
                <label class="text" *ngIf="parcelsData.length>0">Quantidade de parcelas</label>
                <select placeholder="Nenhuma" class="form-control" formControlName="installments" *ngIf="parcelsData.length>0">
                    <option *ngFor="let parc of parcelsData; index as i" [value]="i+1"> 
                        {{parc.qtd}}x {{parc.parcel_value | currency:"BRL"}}
                        <span *ngIf="parc.fee==0">Sem juros</span>
                        <span *ngIf="parc.fee>0">Com juros</span>
                    </option>
                </select>
            </form>
        </div>
        <div class="col-md-6 card-credit">

            <div class="card__front card__part" [ngClass]="back? 'cardBack':'cardFront'">

                <div class="card__front-square card__square"></div>

                <img class="card__front-logo card__logo" [src]="imgBank" width="70">

                <p *ngIf="form.value.card_number != null || form.value.card_number != ''" class="card_numer">
                    {{form.value.card_number | mask:"0000 0000 0000 0000"}}</p>

                <p *ngIf="form.value.card_number==null || form.value.card_number==''" class="card_numer">**** **** ****
                    ****</p>

                <div class="card__space-75 text-left">

                    <span class="card__label">Titular</span>

                    <p class="card__info">{{form.value.holder}}</p>

                </div>

                <div class="card__space-25">

                    <span class="card__label">Expira</span>

                    <p class="card__info">{{form.value.expiration_date | mask:"00/0000"}}</p>

                </div>

            </div>

            <div class="card__back card__part" [ngClass]="back? 'cardFront':'cardBack'">

                <div class="card__black-line"></div>

                <div class="card__back-content">

                    <div class="card__secret">

                        <p *ngIf="form.value.security_code == null || form.value.security_code == ''"
                            class="card__secret--last">***</p>
                        <p *ngIf="form.value.security_code != null || form.value.security_code != ''"
                            class="card__secret--last">{{ form.value.security_code }}</p>

                    </div>

                    <!-- <img class="card__back-square card__square" src="https://image.ibb.co/cZeFjx/little_square.png"> -->

                    <!-- <img *ngIf="imgBank" class="card__back-logo card__logo"
                    src="../../assets/images/bandeiras/{{imgBank}}.png" width="50"> -->

                </div>


            </div>
        </div>

    </div>
</div>
<div align="center">
    <label *ngIf="form.value.type != null" (click)="paymentCard()" type="button"
        class="btn btn-outline-secondary mt-2 mr-5">
        Pagar
    </label>
</div>