import { ChargeService } from './../_services/charge.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import Swal from 'sweetalert2';
 

@Component({
  selector: 'app-charge-screen',
  templateUrl: './charge-screen.component.html',
  styleUrls: ['./charge-screen.component.scss']
})
export class ChargeScreenComponent implements OnInit {

  title = 'Cobrança :: Nativo Pagamentos';

  imgSrcBillet: string = "../../../../../assets/media/icons/paymentIcons/bar-code.svg";
  imgSrcCard: string = "../../../../../assets/media/icons/paymentIcons/credit-card.svg";
  imgSrcTransf: string = "../../../../../assets/media/icons/paymentIcons/transationIcon.svg";

  public date_charge: any;
  public token: any;
  public mode: string = "";
  public checkButton: any = { card: false, boleto: false, transaction: false };

  public transactionValid: boolean = false;

  public page = 1;
  public pageSize = 5;

  public charge_row: any;
  public paginateData: any[] = [];
  
  constructor(
    private route: ActivatedRoute,
    private charge: ChargeService,
    private router: Router
  ) {
  }


  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.token = params["token"];
      if (this.token && this.token.length == 32) {
        this.transactionValid = true;
        this.getDataCharge();
      }
      else {
        this.router.navigate(['error']);

      }
    });
  }

  chargeInvoice(id: any) {
    this.charge.getChargeExternalSub(id).pipe().subscribe(res => {
      if (res.success) {
        this.mode = 'null';
        this.charge_row = res.data;
      } else {
        Swal.fire({ 
          icon: 'error',
          text: res.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    });
  }

  getDataCharge() {
    this.charge.publicTransaction(this.token).pipe().subscribe(res => {
      if (res.success) {
        this.charge.updateChargeData(res.data);
        this.charge.currentChargeData.subscribe(el => {
          this.date_charge = el;
          this.getPagination();
        });
      } else {
        this.router.navigate(['error']);
      }
    });
  }

  getPagination(){
    this.paginateData =  this.date_charge.charge.invoice
    .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  modeFunction(type: string) {
    this.mode = type;

  }
}
