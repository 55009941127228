<div class="row d-flex justify-content-center full-page">

    <div class="col-md-12 col-lg-8 col-sm-12 col-xl-7">

        <div class="card margin-transaction">

            <div class="card-header text-center bg-header">

                <div class="row header-mobile">

                    <div class="col-sm text-left user-data  mt-2">
                        <h4 class="text-white">{{ date_charge?.user?.name }}</h4>
                        <h5 class="text-white">{{ date_charge?.user?.phone | mask:'(00) 0000-0000 || (00) 0 0000-0000'
                            }}</h5>
                        <h5 class="text-white">{{ date_charge?.user?.email }}</h5>

                    </div>

                    <div *ngIf="date_charge?.user?.photo_company == null" class="col-sm  text-right logo-user">

                        <img class="mt-3 mr-4" src="../../../../../assets/media/logos/white-01.png">
                        <h5 class="text-white mt-3 mr-4"><span>CHARGE</span> PROCESSOR</h5>
                    </div>

                    <div *ngIf="date_charge?.user?.photo_company != null" class="col-sm  text-right logo-user">

                        <img class="mt-3 mr-4" src="{{ date_charge.user.photo_company }}">
                    </div>

                </div>

            </div>

            <div class="card-body" *ngIf="date_charge">

                <div class="row mt-4">

                    <div class="col-md-12">

                        <div class="row">

                            <div class="col-md-8">
                                <h6 class="text">Descrição da cobrança:</h6>
                                <span class="text-normal"> {{date_charge?.charge?.description}}</span>

                            </div>

                            <div class="col-md-4">
                                <h6 class="text">Tipo da cobrança:</h6>
                                <span class="text-normal" *ngIf="date_charge?.charge?.payment == 'in_cash'">À
                                    Vista</span>
                                <span class="text-normal"
                                    *ngIf="date_charge?.charge?.payment == 'parceled'">Parcelada</span>
                                <span class="text-normal"
                                    *ngIf="date_charge?.charge?.payment == 'signature'">Assinatura</span>
                                <span class="text-normal"
                                    *ngIf="date_charge?.charge?.payment == 'parcel_single'">Carnê</span>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="row mt-4">

                    <div class="col-md-12">

                        <h6 class="text">Token da cobrança:
                        </h6>
                        <span class="text-normal">
                            {{date_charge?.charge?.token}}</span>
                    </div>

                </div>

                <div class="row mt-5">

                    <div class="col-md-12 d-flex justify-content-star">

                        <h5 class="text">Cliente</h5>

                    </div>

                </div>

                <div class="row mt-2">

                    <div class="col-md-11 justify-content-star">

                        <h6 class="text-normal text-uppercase">{{ date_charge?.client?.name }}</h6>
                        <h6 class="text-normal">{{ date_charge?.client?.email }}</h6>

                    </div>

                </div>
                <div class="row mt-4 mb-2">

                    <div class="col-md-8 justify-content-star">

                        <h6 class="text-normal">Data da criação:
                            <span class="ml-2"> {{date_charge?.charge?.created_at | date:'dd/MM/yyyy HH:mm'}}</span>
                        </h6>

                    </div>
                    <div class="col-md-4 justify-content-star text-price">

                        <h5>Valor:
                            <span class="ml-2"> {{date_charge?.charge?.value | currency:'BRL'}}</span>
                        </h5>

                    </div>

                </div>

                <!-- <div class="mt-2">
                    <hr>
                </div> -->
                <div class="table-responsive" *ngIf="date_charge?.charge?.invoice?.length>0; else noData">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Valor</th>
                                <th scope="col">Vencimento</th>
                                <th scope="col">Última atualização</th>
                                <th scope="col">Status</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of paginateData; let i = index" [ngClass]="{'bg-primary text-white text-bold': row.id ==charge_row?.charge?.id}"> 
                                <th scope="row">{{i+1}}</th>
                                <td>{{row.value | currency:"BRL"}}</td>
                                <td>{{row.due_date | date:'dd/MM/yyyy'}}</td>
                                <td>{{row.updated_at |  date:'dd/MM/yyyy HH:mm'}}</td>
                                <td>
                                    <span class="badge badge-danger text-white" *ngIf="row.status == 'canceled'">Cancelado</span>
                                    <span class="badge badge-warning text-white" *ngIf="row.status == 'waiting'">Aguardando</span>
                                    <span class="badge badge-warning text-white" *ngIf="row.status == 'pending'">Aguardando Confirmação</span>
                                    <span class="badge badge-warning text-white" *ngIf="row.status == 'refused'">Recusado</span>
                                    <span class="badge badge-warning text-white" *ngIf="row.status == 'insufficient'">Valor pago insuficiente</span>
                                    <span class="badge badge-warning text-white" *ngIf="row.status == 'contestation'">Valor pago contestado</span>
                                    <span class="badge badge-warning text-white" *ngIf="row.status == 'expired'">Atrasado</span>
                                    <span class="badge badge-success text-white" *ngIf="row.status == 'paid'">Pago</span>
                                      
                                </td>

                                <td><button type="button" class="btn btn-success btn-sm" (click)="chargeInvoice(row.id)" [disabled]="row.status != 'waiting'">Pagar Agora</button></td> 

                            </tr>
                        </tbody>
                        
                    </table>
 
                    <div class="d-flex justify-content-between p-2" *ngIf="date_charge?.charge?.invoice?.length>5">
                        <ngb-pagination size="sm" [collectionSize]="date_charge?.charge?.invoice?.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="3" [rotate]="true" [boundaryLinks]="true" (pageChange)="getPagination();">
                        </ngb-pagination>

                        <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="getPagination()">
                            <option [ngValue]="5">5 items por página</option>
                            <option [ngValue]="15">15 items por página</option>
                            <option [ngValue]="30">30 items por página</option>
                            <option [ngValue]="50">50 items por página</option>
                            <option [ngValue]="100">100 items por página</option>
                        </select>
                    </div>
                </div>

                <ng-template #noData>
                    <h5 class="m-3 text-center">Nenhuma cobrança encontrada!</h5>
                </ng-template>


                <div *ngIf="charge_row?.charge?.id">

                    <div class="row mt-4">

                        <div class="col-md-12">
    
                            <h4 class="text-center text-danger mb-4" *ngIf="charge_row.charge.value_finally != charge_row.charge.value">Cobrança vencida! Regularize agora. </h4>
                           
                        </div>

                        <div class="col-md-12">
    
                            <h6 class="text">Valor a ser pago:
                                <span class="text-normal" *ngIf="charge_row.charge.value_finally == charge_row.charge.value">{{charge_row.charge.value  | currency:"BRL"}}</span>
                                <span class="text-normal" *ngIf="charge_row.charge.value_finally != charge_row.charge.value">{{charge_row.charge.value_finally  | currency:"BRL"}}</span>
                            </h6>
                            
                        </div>
                        
                        <div class="col-md-12">
    
                            <h6 class="text">Vencimento:<span class="text-normal">
                                {{charge_row.charge.due_date | date:'dd/MM/yyyy'}}</span></h6>
                            
                        </div>
                        <div class="col-md-12">
    
                            <h6 class="text">Multa por atraso: <span class="text-normal">
                                {{charge_row.charge.fine_value}}%</span></h6>
                           
                        </div>
                        <div class="col-md-12">
    
                            <h6 class="text">Juros mensal:<span class="text-normal">
                                {{charge_row.charge.fine_month}}%</span></h6>
                            
                        </div>
                        
    
                    </div>

                    <div class="row mt-5">

                        <div class="col-md-12 d-flex justify-content-star">

                            <h6 class="text">Métodos de pagamento</h6>

                        </div>

                    </div>

                    <div class="row selection-type-div">

                        <!-- Option billet -->
                        <label *ngIf="charge_row.charge.type_billet == 'yes'"
                            class="col-sm btn btn-outline-primary optionButtonCard btn-lg"
                            (click)="modeFunction('boleto')" [ngClass]="mode=='boleto'?'active':''"
                            (mouseover)="imgSrcBillet = '../../../../../assets/media/icons/paymentIcons/bar-codeActive.svg'"
                            (mouseout)="imgSrcBillet = '../../../../../assets/media/icons/paymentIcons/bar-code.svg'">
                            <img *ngIf="mode!='boleto'" class="imgButton" [src]="imgSrcBillet">
                            <img *ngIf="mode==='boleto'" class="imgButton"
                                src="../../../../../assets/media/icons/paymentIcons/bar-codeActive.svg">
                            <p>Boleto</p>
                        </label>

                        <!-- Option card  -->
                        <label *ngIf="charge_row.charge.type_card == 'yes'"
                            class="col-sm btn btn-outline-primary optionButtonCard btn-lg" (click)="mode='card'"
                            [ngClass]="mode=='card'?'active':''"
                            (mouseover)="imgSrcCard = '../../../../../assets/media/icons/paymentIcons/credit-cardActive.svg'"
                            (mouseout)="imgSrcCard = '../../../../../assets/media/icons/paymentIcons/credit-card.svg'">
                            <img *ngIf="mode!='card'" class="imgButton" [src]="imgSrcCard">
                            <img *ngIf="mode==='card'" class="imgButton"
                                src="../../../../../assets/media/icons/paymentIcons/credit-cardActive.svg">
                            <p>Cartão crédito</p>
                        </label>

                        <!-- Option transfer -->
                        <label *ngIf="charge_row.charge.type_transfer == 'yes'"
                            class="col-sm btn btn-outline-primary optionButtonCard btn-lg" (click)="mode='transf'"
                            [ngClass]="mode=='transf'?'active':''"
                            (mouseover)="imgSrcTransf = '../../../../../assets/media/icons/paymentIcons/transationIconActive.svg'"
                            (mouseout)="imgSrcTransf = '../../../../../assets/media/icons/paymentIcons/transationIcon.svg'">
                            <img *ngIf="mode!='transf'" class="imgButton" [src]="imgSrcTransf">
                            <img *ngIf="mode==='transf'" class="imgButton"
                                src="../../../../../assets/media/icons/paymentIcons/transationIconActive.svg">
                            <p>Transferência</p>
                        </label>
                    </div>

                    <ng-container *ngIf="mode==='boleto'; else card">
                        <app-billet-option [id]="charge_row.charge.id"></app-billet-option> 
                    </ng-container>

                    <ng-template #card>
                        <ng-container *ngIf="mode==='card'; else transf"> 
                            <app-card-option [id]="charge_row.charge.id"></app-card-option>
                        </ng-container>
                    </ng-template>
                    <ng-template #transf>
                        <ng-container *ngIf="mode==='transf'">
                            <app-transf-option [banks]="date_charge?.banks" [id]="charge_row.charge.id"></app-transf-option>
                        </ng-container>
                    </ng-template>
                </div>
                <ng-template #paymentPending>
                    <div class="justify-content-star text-status">

                        <h6 class="text">Pagamento:</h6>

                        <h5 *ngIf="date_charge?.charge?.status =='pending'">
                            <span class="ml-2 status-pending"> Cobrança com pagamento em análise</span>
                        </h5>
                        <h5 *ngIf="date_charge?.charge?.status =='paid'">
                            <span class="ml-2 status-success"> Cobrança paga</span>
                        </h5>
                        <h5 *ngIf="date_charge?.charge?.status =='refused'">
                            <span class="ml-2 status-refused"> Pagamento de cobrança recusado</span>
                        </h5>
                        <h5 *ngIf="date_charge?.charge?.status =='insufficient'">
                            <span class="ml-2 status-insufficient"> Pagamento insuficiente para pagamento da cobrança,
                                entre em contato para pagamento da diferença</span>
                        </h5>
                        <h5 *ngIf="date_charge?.charge?.status =='canceled'">
                            <span class="ml-2 status-canceled"> Cobrança cancelada</span>
                        </h5>

                    </div>
                </ng-template>



                <div class="mt-2">
                    <hr>
                </div>

                <div class="row mt-5">

                    <div class="col-md-12 d-flex text-center">

                        <h6 class="text">Fatura intermediada por nativopagamentos.com, abra sua conta e desfrute de
                            nossos serviços de cobranças.</h6>

                    </div>

                </div>

            </div>

            <div class="card-footer mt-5 bg-white border-0 mb-3">

                <div class="row">

                    <div class="col-sm text-start social">

                        <a target="_blank" href="https://www.nativopagamentos.com/">
                            <img class="ml-4 mt-2 logo-img" src="../../../../../assets/media/logos/se1.png"
                                width="170px">
                        </a>

                        <a target="_blank" href="https://www.facebook.com/nativopagamentos-105414984731198/">
                            <img class="ml-4 mt-2 img-social" src="../../../../../assets/media/icons/facebook.svg">
                        </a>

                        <a target="_blank" href="https://www.instagram.com/nativopagamentos/">
                            <img class="ml-2 mt-2 img-social" src="../../../../../assets/media/icons/instagram.svg">
                        </a>



                    </div>

                    <div class="col-sm suport-data">

                        <h4 class="mt-3 mr-4 text" style="font-size: 18px !important;"> nativopagamentos.com</h4>

                        <p class="mr-4 text-normal">suporte@nativopagamentos.com</p>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>